import styled, { css } from 'styled-components'

export const Breakpoint = {
  downFromComputer: '(max-width: 991.98px)',
  downFromSmallDesktop: '(max-width: 1199.98px)',
  downFromSmallMobile: '(max-width: 479.98px)',
  downFromTablet: '(max-width: 768px)',
  fromTabletToSmallDesktop: '(min-width: 768px) and (max-width: 1199.98px)',
  onlyComputer: '(min-width: 992px)',
  onlyLargeDesktop: '(min-width: 1199.98px)',
  onlyMobile: '(max-width: 767.98px)',
  onlySmallDesktop: '(min-width: 992px) and (max-width: 1199.98px)',
  onlyTablet: '(min-width: 768px) and (max-width: 991.98px)',
  upFromMobile: '(min-width: 480px)',
  upFromTablet: '(min-width: 768px)',
} as const

export const Colors = {
  black: '#0E0C0B',
  blue100: '#ECEDF0',
  blue1000: '#24272E',
  blue200: '#DEE1E6',
  blue300: '#D0D3DA',
  blue400: '#C0C4CD',
  blue50: '#F9F9FA',
  blue500: '#D9DDE8',
  blue600: '#99A0AF',
  blue700: '#81899C',
  blue800: '#636C80',
  blue900: '#3A3F4B',
  blueSelect: '#98AFE1',
  gray100: '#F6F5F4',
  gray1000: '#0E0C0B',
  gray150: '#EDEBEA',
  gray200: '#E7E5E4',
  gray300: '#D6D3D1',
  gray400: '#A8A29E',
  gray50: '#FAFAFA',
  gray500: '#78716C',
  gray600: '#57534E',
  gray700: '#44403C',
  gray800: '#292524',
  gray900: '#1C1917',
  grayLight: '#E5E5E5',
  green100: '#EEEEEA',
  green1000: '#3D4233',
  green200: '#E2E1DB',
  green300: '#D1D5CA',
  green400: '#C6C4B8',
  green50: '#F9FAF8',
  green500: '#AFB6A4',
  green600: '#9BA38D',
  green700: '#8D8A72',
  green800: '#677057',
  green900: '#919D7A',
  greenBright: '#9DA56E',
  greenBright50: '#D6E5BC',
  orange100: '#C77357',
  orange200: '#EFE0C8',
  orange300: '#E8D1AF',
  orange400: '#E0C193',
  orange50: '#F6EDDF',
  orange500: '#E98E6F',
  orange700: '#C1975B',
  orange800: '#785C33',
  red100: '#F9EBE6',
  red1000: '#392119',
  red200: '#F4DCD4',
  red300: '#EFCBC0',
  red400: '#E9B9A9',
  red50: '#FDF8F7',
  red500: '#E2A590',
  red600: '#DA8C72',
  red700: '#C77357',
  red800: '#9D5B45',
  red900: '#5C3528',
  redBright: '#FFBBAC',
  white: '#FFFFFF',
  yellow100: '#F5EDD7',
  yellow1000: '#272111',
  yellow200: '#EDE0BA',
  yellow50: '#FCF9F2',
  yellow400: '#DBC276',
  yellow500: '#CDB05A',
  yellow600: '#B79E50',
  yellow700: '#9D8745',
  yellow800: '#7C6B36',
  yellow900: '#493E20',
  yellowBright: '#FFECBA',
} as const

export const LineBorder = `1px solid ${Colors.gray200}`

export const FormDivider = styled.div<{ noMargin?: boolean; margin?: string }>`
  border-bottom: ${LineBorder};
  margin: ${(props) => (props.noMargin ? '0' : props.margin || '30px 0')};
  width: 100%;

  @media ${Breakpoint.onlyMobile} {
    margin: ${(props) =>
      props.noMargin ? '0' : props.margin || '16px 0'} !important;
  }
`

const HeaderStyle = css`
  color: ${Colors.black};
  font-family: 'GT America';
  font-weight: 100;
  line-height: 135%;

  /* Bold header */
  .bold {
    font-weight: 300;
  }
`

export const StyledSystem = css`
  background: ${Colors.gray50};
  color: ${Colors.black};
  height: 100%;
  min-height: 100vh;

  a {
    color: unset;
    font-size: 16px;
    text-decoration: unset;
    :hover {
      color: unset;
    }
  }

  h1 {
    ${HeaderStyle}
    font-size: 43px;
    line-height: 56px;
  }

  h2 {
    ${HeaderStyle}
    font-size: 35px;
    line-height: 49px;
  }

  h3 {
    ${HeaderStyle}
    font-size: 29px;
    line-height: 43px;
  }

  h4 {
    ${HeaderStyle}
    font-size: 24px;
    line-height: 36px;
  }

  h5 {
    ${HeaderStyle}
    font-family: 'GT America';
    font-size: 21px;
    line-height: 32px;
  }

  h6 {
    ${HeaderStyle}
    font-family: 'GT America';
    font-size: 18px;
    line-height: 27px;
    margin: 0 0 16px;
  }

  p,
  textarea {
    color: ${Colors.black};
    font-family: 'GT America';
    font-size: 16px;
    font-weight: 100;
    line-height: 24px;
  }

  p.small {
    font-size: 14px;
  }

  p.overline {
    font-size: 14px;
  }

  p.caption {
    font-size: 15px;
    font-weight: 300;
  }

  p.x-small {
    font-size: 12px;
  }

  /* Bold text */
  .bold {
    font-weight: 300;
  }

  .orange {
    color: ${Colors.orange500};
  }

  .gray {
    color: ${Colors.gray600} !important;
  }

  .light-gray {
    color: ${Colors.gray500} !important;
  }

  .text-button {
    color: ${Colors.black};
    font-family: 'GT America' !important;
    font-size: 15px !important;
    text-transform: capitalize;
    font-weight: 300;
    line-height: 22px;
  }

  .underlined {
    border-bottom: 1px solid ${Colors.blue300};
    position: relative;
    width: 100%;
    text-align: left;
    padding-bottom: 3px;
    margin-bottom: 25px;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  .flex {
    align-items: center;
    display: flex !important;
    flex-wrap: wrap;
  }

  .flex-start {
    align-items: flex-start;
    display: flex !important;
  }

  .flex-center {
    align-items: center;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    min-width: unset;
  }

  .red {
    color: ${Colors.red700};
  }

  img.avatar {
    border-radius: 100% !important;
    margin: 0;
  }

  .no-margin {
    margin: 0 !important;
  }

  .no-padding {
    padding: 0 !important;
  }

  div.row.row-gap {
    @media ${Breakpoint.upFromMobile} {
      row-gap: 24px;
    }

    @media ${Breakpoint.onlyMobile} {
      row-gap: 0;
    }
  }

  /* Responsive classes */
  .only-mobile {
    display: none !important;
    @media ${Breakpoint.onlyMobile} {
      display: inline-flex !important;
    }
  }

  .up-mobile {
    display: inline-flex !important;
    @media ${Breakpoint.onlyMobile} {
      display: none !important;
    }
  }

  .only-computer {
    display: inline-flex !important;
    @media ${Breakpoint.downFromComputer} {
      display: none !important;
    }
  }

  .down-computer {
    display: none !important;
    @media ${Breakpoint.downFromComputer} {
      display: inline-flex !important;
    }
  }

  /* CSS specific to iOS devices */
  @supports (-webkit-touch-callout: none) {
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }
`

export const StyledContent = styled.section`
  ${StyledSystem}
`

export const ScrollBarStyle = css`
  /* ScrollBar */
  ::-webkit-scrollbar {
    width: 4px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${Colors.gray100};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray800};
    border-radius: 0;
    :hover {
      background-color: ${Colors.gray800};
    }
  }
`

export const ScrollBarThickStyle = css`
  /* ScrollBar */
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${Colors.gray50};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray300};
    border-radius: 8px;
    border: 3px solid ${Colors.gray50};
    :hover {
      background-color: ${Colors.gray800};
    }
  }

  @media ${Breakpoint.onlyMobile} {
    ::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0;
      border: none;
    }
  }
`

export const ScrollContainer = styled.section`
  height: 200px;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  ${ScrollBarStyle}

  @media print {
    overflow: visible !important;
    height: unset !important;
    min-height: unset !important;
    overflow-y: unset !important;
    overflow-x: unset !important;
  }
`

export const StyledCellDiv = styled.div<{
  asLink?: string
}>`
  cursor: ${(props) => (props.asLink ? 'pointer' : 'default')};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`
